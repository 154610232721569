import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import demo1 from '../assets/images/confirm.svg';
import checks from '../assets/images/checks.svg';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
                Identity Verification for People and Businesses in Africa.
              </h1>
                <a href="https://app.vibranium.id/merchant/register" className="btn btn-outline btn-xl">
                  Get Demo
                </a>
            </div>
          </div>

          <div className="col-lg-5 my-auto">
            <div className="container">
                    <img src={demo1} className="img-fluid" alt="" />
                  <div className="button"></div>
            </div>
          </div>

        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="kyc">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
            Reliable end-to-end identity verification services.
            </h2>
            <p>
            Fulfil your AML/KYC compliance requirements using our automated ID capture solution when enrolling new users on your service.
            </p>
            <div className="badges">
              <a className="badge-link" href="https://app.vibranium.id/">
                <img src={demo1} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="dashboard">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Instant Verification and Background Checks</h2>
          <p className="text-muted">
            Confirm that a person is who they say they are to deter fraud in your transactions across Africa.
          </p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">

                <div className="device">
                  <div className="screen">
                    <img src={checks} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>

            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Customer Onboarding</h3>
                    <p className="text-muted">
                      Fulfill your AML/KYC compliance requirements using our automated ID capture 
                      solution when enrolling new users on your service.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Liveness Detection</h3>
                    <p className="text-muted">
                      Artificial Intelligence enabled detection to determine if someone 
                      is physically present during a transaction. Boost conversion rates
                      by 15% with detailed realtime feedback.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Verification API</h3>
                    <p className="text-muted">
                      Enable real-time Identity Verification and Screening in your 
                      application by implementing our REST API. Use our mobile and web SDKs 
                      for user onboarding.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Know Your Business</h3>
                    <p className="text-muted">
                      Perform due dilligence on businesses across Africa to confirm that 
                      the person you are dealing with is the legitimate owner and that the 
                      business is a legitimate entity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2>
            Get Started
          </h2>
            <a href="https://app.vibranium.id/" className="btn btn-outline btn-xl">
              Sign Up
            </a>
        </div>
      </div>
      <div className="overlay"></div>
    </section>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          Get in touch
        </h2>
        <p>info@vibranium.id
        <br />
          5th Floor, @iBizAfrica, Ole-Sangale Road
        <br />
P.O. Box 5225 - 00200, Nairobi - Kenya
</p>
        <Social />
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;

module.exports = {
  siteTitle: 'Vibranium ID', // <title>
  manifestName: 'vibranium',
  manifestShortName: 'Vibranium ID', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/vibranium.jpeg',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  socialLinks: [
    {
      icon: 'fa-medium',
      name: 'Medium',
      url: 'https://medium.com/vibranium-id',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/company/vibranium-id',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/vibraniumid',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/Vibranium-ID',
    }
  ],
};
